<template>
  <el-container>
    <el-header height="220">
      <el-select
        @change="typeChange"
        clearable
        v-model="queryInfo.examType"
        placeholder="请选择考试类型"
      >
        <el-option
          v-for="item in examType"
          :key="item.type"
          :label="item.info"
          :value="item.type"
        >
        </el-option>
      </el-select>

      <el-input
        v-model="queryInfo.examName"
        placeholder="考试名称"
        @blur="getExamInfo"
        style="margin-left: 5px; width: 220px"
        prefix-icon="el-icon-search"
      ></el-input>
    </el-header>

    <el-main>
      <el-table
        ref="questionTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="examInfo"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column align="center" label="考试名称">
          <template slot-scope="scope">
            {{ scope.row.examName }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="考试类型">
          <template slot-scope="scope">
            {{ scope.row.type === 1 ? "完全公开" : "需要密码" }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="考试时间">
          <template slot-scope="scope">
            {{
              scope.row.startTime !== "null" && scope.row.endTime !== "null"
                ? scope.row.startTime + " ~" + scope.row.endTime
                : "不限时"
            }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="考试时长">
          <template slot-scope="scope">
            {{ scope.row.duration + "分钟" }}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          prop="totalScore"
          label="考试总分"
        ></el-table-column>

        <el-table-column
          align="center"
          prop="passScore"
          label="及格分数"
        ></el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              :disabled="!checkExam(scope.row)"
              @click="toStartExam(scope.row)"
              :icon="
                checkExam(scope.row) ? 'el-icon-caret-right' : 'el-icon-close'
              "
              :type="checkExam(scope.row) ? 'primary' : 'info'"
            >
              {{ checkExam(scope.row) ? "去考试" : "暂不开放" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        style="margin-top: 25px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-main>
    <el-dialog
      title="考试提示"
      :visible.sync="startExamDialog"
      :close-on-click-modal="false"
      center
      width="50%"
    >
      <el-alert
        title="点击`开始考试`后将自动进入考试，请诚信考试，考试过程中可能会对用户行为、用户视频进行截图采样，请知悉！"
        type="error"
      >
      </el-alert>

      <el-card style="margin-top: 25px">
        <span>考试名称：</span>{{ currentSelectedExam.examName }}
        <br />
        <span>考试描述：</span>{{ currentSelectedExam.examDesc }}
        <br />
        <span>考试时长：</span>{{ currentSelectedExam.duration + "分钟" }}
        <br />
        <span>试卷总分：</span>{{ currentSelectedExam.totalScore }}分
        <br />
        <span>及格分数：</span>{{ currentSelectedExam.passScore }}分
        <br />
        <span>开放类型：</span>
        {{ currentSelectedExam.type === 2 ? "需要密码" : "完全公开" }}
        <br />
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button @click="startExamDialog = false">返 回</el-button>
        <el-button
          type="primary"
          @click="
            $router.push(
              '/exam/' + currentSelectedExam.examId + '/' + userInfoId
            )
          "
          >开始考试</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import exam from "@/api/exam";
import vueEsign from "vue-esign";

export default {
  name: "ExamOnline",
  components: { vueEsign },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(value)) {
        return callback("请输入正确的手机号");
      }
      callback();
    };
    const checkId = (rule, value, callback) => {
      if (
        !/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        )
      ) {
        return callback("请输入格式正确的身份证号");
      }
      callback();
    };
    return {
      userInfoId: "",
      selectRow: "",
      knowObject: {
        name: "",
        part: "",
        relation: "",
      },
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      signDialog: false,
      educateArray: [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
      ],
      workArray: [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
      ],
      relationArray: [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ],
      marryArray: [
        { id: 1, name: "未婚" },
        { id: 2, name: "已婚" },
        { id: 3, name: "离异" },
        { id: 4, name: "其他" },
      ],
      sexArray: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      isNoArray: [
        { id: 1, name: "无" },
        { id: 2, name: "有" },
      ],
      isView: false,
      ruleForm: {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      },
      queryInfo: {
        examType: null,
        startTime: null,
        endTime: null,
        examName: null,
        pageNo: 0,
        pageSize: 10,
      },
      //表格是否在加载
      loading: true,
      //考试类型信息
      examType: [
        {
          info: "公开考试",
          type: 1,
        },
        {
          info: "需要密码",
          type: 2,
        },
      ],
      //考试信息
      examInfo: [],
      //查询到的考试总数
      total: 0,
      //开始考试的提示框
      startExamDialog: false,
      //当前选中的考试的信息
      currentSelectedExam: {},
    };
  },
  created() {
    this.getExamInfo();
  },
  methods: {
    autoGetAge() {
      // console.log()
      if (
        this.ruleForm.idNo &&
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          this.ruleForm.idNo
        )
      ) {
        console.log(
          new Date().getFullYear(),
          this.ruleForm.idNo.substring(6, 11)
        );
        this.ruleForm.age =
          new Date().getFullYear() -
          Number(this.ruleForm.idNo.substring(6, 10));
      }
    },
    validateDate(v1, v2) {
      console.log("v1", v1, v2);
      if (v1 && v2) {
        let date1 = new Date(v1).getTime();
        let date2 = new Date(v2).getTime();
        console.log(date1, date2);
        if (date1 > date2) {
          this.$message.warning("起始时间应小于结束时间");
        }
      }
    },
    returnBackInfo() {
      this.userInfoDialog = false;
      this.knowObject = {
        name: "",
        part: "",
        relation: "",
      };
      this.educateArray = [
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
        {
          schoolName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          major: "",
          status: "",
        },
      ];
      this.workArray = [
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
        {
          companyName: "",
          startTime: "",
          endTime: "",
          timeArray: ["", ""],
          city: "",
          post: "",
          reason: "",
        },
      ];
      this.relationArray = [
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
        {
          name: "",
          age: "",
          relation: "",
          companyNamePost: "",
          contact: "",
        },
      ];
      this.ruleForm = {
        caseDetail: "",
        medicalHistoryDetail: "",
        companyName: "",
        knowPeopleInfo: "",
        otherMarital: "",
        appliedPosition: "",
        name: "",
        idNo: "",
        gender: "",
        nativePlace: "",
        nation: "",
        telNo: "",
        age: "",
        height: "",
        weight: "",
        highestEducation: "",
        highestDegree: "",
        personalTaboo: "",
        personalTabooDetail: "",
        political: "",
        joinTime: "",
        maritalStatus: "",
        address: "",
        commuteHours: "",
        businessTravel: "",
        workOvertime: "",
        relocation: "",
        monthlyIncome: "",
        annualIncome: "",
        cardinal: "",
        expectSalary: "",
        laborRelation: "",
        legalCase: "",
        medicalHistory: "",
        hasKnowPeople: "",
        hobby: "",
        advantage: "",
        shortcoming: "",
        honor: "",
        learningExperienceList: [
          // {
          //   schoolName: "",
          //   startTime: "",
          //   endTime: "",
          //   major: "",
          //   status: "",
          // },
        ],
        workExperienceList: [
          // {
          //   companyName: "",
          //   startTime: "",
          //   endTime: "",
          //   city: "",
          //   post: "",
          //   reason: "",
          // },
        ],
        socialRelationList: [
          // {
          //   name: "",
          //   age: "",
          //   relation: "",
          //   companyNamePost: "",
          //   contact: "",
          // },
        ],
        knowWay: "",
        checkDate: "",
        agree: "",
        signTime: "",
        signName: "",
      };
    },
    submitInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.resultImg === "") {
            this.$message.warning("请输入签名");
            return;
          }
          if (this.ruleForm.agree === "") {
            this.$message.warning("请选择是否同意");
            return;
          }
          let tempData = JSON.parse(JSON.stringify(this.ruleForm));
          const formData = new FormData();
          this.base64ImgtoFile(this.resultImg);
          formData.append("file", this.base64ImgtoFile(this.resultImg));
          exam.uploadFile(formData).then((resp) => {
            if (resp.code === 200) {
              console.log("resp", resp);
              this.educateArray.forEach((item) => {
                tempData.learningExperienceList.push({
                  schoolName: item.schoolName,
                  startTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[0]
                      : "",
                  endTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[1]
                      : "",
                  major: item.major,
                  status: item.status,
                });
              });
              this.workArray.forEach((item) => {
                tempData.workExperienceList.push({
                  companyName: item.companyName,
                  startTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[0]
                      : "",
                  endTime:
                    item.timeArray && item.timeArray.length > 0
                      ? item.timeArray[1]
                      : "",
                  city: item.city,
                  post: item.post,
                  reason: item.reason,
                });
              });
              this.relationArray.forEach((item) => {
                tempData.socialRelationList.push({
                  name: item.name,
                  age: item.age,
                  relation: item.relation,
                  companyNamePost: item.companyNamePost,
                  contact: item.contact,
                });
              });
              tempData.signName = resp.data.key;
              if (this.ruleForm.hasKnowPeople == 2) {
                tempData.knowPeopleInfo =
                  this.knowObject.name +
                  "," +
                  this.knowObject.part +
                  "," +
                  this.knowObject.relation;
              } else {
                tempData.knowPeopleInfo = "";
              }
              console.log("tempData", tempData);

              exam.saveUserInfo(tempData).then((resInfo) => {
                if (resInfo.code === 200) {
                  this.$message.info("保存成功");
                  this.userInfoId = resInfo.data;
                  this.returnBackInfo();
                  if (this.selectRow.type === 2) {
                    this.$prompt("请提供考试密码", "Tips", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                    })
                      .then(({ value }) => {
                        if (value === this.selectRow.password) {
                          this.startExamDialog = true;
                          this.currentSelectedExam = this.selectRow;
                        } else {
                          this.$message.error("密码错误o(╥﹏╥)o");
                        }
                      })
                      .catch(() => {});
                  } else {
                    this.startExamDialog = true;
                    this.currentSelectedExam = this.selectRow;
                  }
                } else {
                  this.$message.error(resInfo.message);
                }
              });
            }
          });
        }
      });
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          console.log("this.resultImg", this.resultImg);
          this.signDialog = false;
        })
        .catch((err) => {
          this.$message.warning("签名不能为空");
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    //考试类型搜索
    typeChange(val) {
      this.queryInfo.examType = val;
      this.getExamInfo();
    },
    //查询考试信息
    getExamInfo() {
      exam.getExamInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          resp.data.data.forEach((item) => {
            item.startTime = String(item.startTime).substring(0, 10);
            item.endTime = String(item.endTime).substring(0, 10);
          });
          this.examInfo = resp.data.data;
          this.total = resp.data.total;
          this.loading = false;
        }
      });
    },
    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getExamInfo();
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val;
      this.getExamInfo();
    },
    //去考试准备页面
    toStartExam(row) {
    exam.getUserInfo(formData).then((resp) => {
       if (resp.code === 200) {
       }
     });

       this.$nextTick(() => {
         console.log(
           "this.$refs.datePicker",
           this.$refs.datePicker
         );
         this.$refs.datePicker.$el.childNodes[1].readOnly = true;
       });
       if (row.type === 2) {
         this.$prompt("请提供考试密码", "Tips", {
           confirmButtonText: "确定",
           cancelButtonText: "取消",
         })
           .then(({ value }) => {
             if (value === row.password) {
               this.startExamDialog = true;
               this.currentSelectedExam = row;
             } else {
               this.$message.error("密码错误o(╥﹏╥)o");
             }
           })
           .catch(() => {});
       } else {
         this.startExamDialog = true;
         this.currentSelectedExam = row;
       }
    },
  },
  computed: {
    //检查考试的合法性
    checkExam(row) {
      return (row) => {
        let date = new Date();
        if (row.status === 2) return false;
        if (row.startTime === "null" && row.endTime === "null") {
          return true;
        } else if (row.startTime === "null") {
          return date < new Date(row.endTime);
        } else if (row.endTime === "null") {
          return date > new Date(row.startTime);
        } else if (
          date > new Date(row.startTime) &&
          date < new Date(row.endTime)
        )
          return true;
      };
    },
  },
};
</script>
<style scoped>
.form-class2 {
  margin-right: 20px;
}
.form-class2 >>> .el-form-item__content {
  width: 220px;
}
.top-label >>> .el-form-item__label {
  width: 350px !important;
  text-align: left;
  display: block !important;
}
.top-top-label >>> .el-form-item__label {
  width: 395px !important;
  text-align: left;
  display: block !important;
}
.long-label >>> .el-form-item__label {
  width: 230px !important;
  /* text-align: left;
  display: block !important; */
}
/* .long-label >>> .el-form-item__label label {
  display: block;
}
.top-label >>> .el-form-item__label label {
  display: block;
}
.top-top-label >>> .el-form-item__label label {
  display: block;
} */
.middle-label >>> .el-form-item__label {
  width: 180px !important;
  /* text-align: left; */
}
</style>
<style scoped lang="scss">
@import "../../assets/css/student/examOnline";
</style>
